// Derive dollar donation amount by summing line items in stripe webhook payload
// (look for field "display_items" -- amounts are denominated in cents)
const donationFromItems = items =>
  items.reduce((sum, item) => sum + item.amount, 0) / 100.0

//This represents a 2.22% (+30 cents) fee charged by Stripe for 5013c organizations as of 11/22
const percentageFeeDecimal = 0.022
const flatFee = 0.3
const getTransactionFees = dollars =>
  Math.ceil(
    ((dollars * percentageFeeDecimal + flatFee) / (1 - percentageFeeDecimal)) *
      100
  ) / 100

const makeCommaSeparatedString = (arr, useOxfordComma) => {
  const listStart = arr.slice(0, -1).join(", ")
  const listEnd = arr.slice(-1)
  const conjunction =
    arr.length <= 1 ? "" : useOxfordComma && arr.length > 2 ? ", and " : " and "
  return [listStart, listEnd].join(conjunction)
}

const formatFamilyNamesFromNeeds = needs => {
  const familyNames = []
  for (let need of needs) {
    familyNames.push(need.family.display_name)
  }
  let uniqueFamilyNames = [...new Set(familyNames)]
  const formattedFamilyNames = makeCommaSeparatedString(uniqueFamilyNames, true)
  return formattedFamilyNames
}

const formatNeedTypesFromNeeds = needs => {
  const needTypes = []
  for (let need of needs) {
    needTypes.push(need.need_type)
  }
  let needTypeSet = [...new Set(needTypes)]
  const formattedNeedTypes = makeCommaSeparatedString(needTypeSet, true)
  return formattedNeedTypes
}

const getThankYouEmailTemplate = needs => {
  if (
    needs.some(
      need =>
        fairfieldCountyLocations.includes(need.family.location_text) &&
        need.need_type == "EDUCATION"
    )
  ) {
    return "fairfieldSynchronyCampaign"
  }
}

const fairfieldCountyLocations = [
  "Bethel, CT",
  "Bridgeport, CT",
  "Brookfield, CT",
  "Danbury, CT",
  "Darien, CT",
  "Easton, CT",
  "Fairfield, CT",
  "Greenwich, CT",
  "Monroe, CT",
  "New Canaan, CT",
  "New Fairfield, CT",
  "Newtown, CT",
  "Norwalk, CT",
  "Redding, CT",
  "Ridgefield, CT",
  "Shelton, CT",
  "Sherman, CT",
  "Stamford, CT",
  "Stratford, CT",
  "Trumbull, CT",
  "Weston, CT",
  "Westport, CT",
  "Wilton, CT",
]

const bostonAreaLocations = [
  "Boston, MA",
  "Cambridge, MA",
  "Somerville, MA",
  "Quincy, MA",
  "Newton, MA",
  "Brookline, MA",
  "Chelsea, MA",
  "Everett, MA",
  "Malden, MA",
  "Medford, MA",
  "Revere, MA",
  "Waltham, MA",
  "Watertown, MA",
  "Arlington, MA",
  "Lexington, MA",
  "Woburn, MA",
  "Burlington, MA",
  "Braintree, MA",
  "Dedham, MA",
  "Milton, MA",
  "Needham, MA",
  "Norwood, MA",
  "Weymouth, MA",
  "Randolph, MA",
  "Framingham, MA",
  "Peabody, MA",
  "Lynn, MA",
  "Salem, MA",
  "Winthrop, MA",
  "Saugus, MA",
  "Stoneham, MA",
  "Melrose, MA",
  "Wakefield, MA",
  "Belmont, MA",
  "Bedford, MA",
  "Chelsea, MA",
  "Dorchester, MA",
  "East Boston, MA",
  "Roxbury, MA",
]

const allNeeds = [
  "HOUSING",
  "CHILDCARE",
  "GROCERIES",
  "TRANSPORT",
  "BILLS",
  "EDUCATION",
  "FURNISHINGS",
  "MISCELLANEOUS NEEDS",
  "MEDICAL",
  "CLOTHING",
]

module.exports = {
  donationFromItems,
  getTransactionFees,
  makeCommaSeparatedString,
  formatFamilyNamesFromNeeds,
  formatNeedTypesFromNeeds,
  getThankYouEmailTemplate,
  fairfieldCountyLocations,
  bostonAreaLocations,
  allNeeds,
}
