import React, { useState } from "react"
import styles from "./styles.module.scss"
import IconButton from "../IconButton"
import { CloseOutlined } from "@ant-design/icons"
import BannerIconImage from "../../assets/tada.png"
import { navigate, Link } from "@reach/router"

const CampaignBanner = ({
  showClose = true,
  banner,
  BannerIcon,
  buttonText,
  buttonLink,
  suppressIcon,
}) => {
  const handleClick = e => navigate(e.target.getAttribute("href"))
  const [dismissed, setDismissed] = useState(false)

  const dismiss = () => {
    setDismissed(true)
  }

  if (dismissed) {
    return null
  }
  // just adding the christmas campaign here -- we should probably serialize this
  // and do it neatly either through optimizely or just store it in SQL
  const getIcon = () => {
    if (suppressIcon) {
      return <span />
    } else {
      return BannerIcon ? (
        <BannerIcon />
      ) : (
        <img
          src={BannerIconImage}
          className={styles.campaignIcon}
          alt="banner-icon"
        />
      )
    }
  }
  return (
    <div className={styles.bannerContainer}>
      <div className={styles.bannerContent}>
        {showClose && (
          <IconButton
            icon={<CloseOutlined className={styles.closeButton} />}
            onClick={dismiss}
          />
        )}
        {getIcon()}
        <p className={styles.bannerText}>{banner}</p>
      </div>

      <Link to={buttonLink} onClick={handleClick}>
        <button className={styles.bannerButton}>{buttonText}</button>
      </Link>
    </div>
  )
}

export default CampaignBanner
